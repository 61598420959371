/**
 * Created by Hennadiy on 13.02.2023
 */

import {action, makeAutoObservable} from 'mobx';
import {ServiceStore} from 'gears-react';

import Services from '../api/services';
import * as types from '../api/types';

export class Store {
    constructor() {
        makeAutoObservable(this);
    }

    private list: types.IGetQuizSession[] = [];

    @action
    public setList(list: types.IGetQuizSession[]) {
        this.list = list;
    }

    public getList() {
        return this.list;
    }

    //offset
    public offset: number = 0;

    @action
    public setOffset(offset: number) {
        this.offset = offset;
    }

    //search
    public search: string = '';

    @action
    public setSearch(search: string) {
        this.search = search;
    }

    //limit
    public limit: number = 10;


    //type
    public type: number | undefined = undefined;

    @action
    public setType(type: number | undefined) {
        this.type = type;
    }

    //category
    public category: number = 0;

    @action
    public setCategory(category: number) {
        this.category = category;
    }

    public loading: boolean = true;

    @action
    public setLoading(loading: boolean) {
        this.loading = loading;
    }

    public readonly getQuizSessionList = new ServiceStore<[AbortController, types.IGetQuizSessionListParams],
        types.IListDataResponse<types.IGetQuizSession>>(Services.getQuizSessionList, true);
}