import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';

type Props = {
    children?: React.ReactNode;
    offset: number;
    setOffset: (offset: number) => void | Dispatch<SetStateAction<number>>;
    isLoading: boolean;
    totalCount: number;
    nextOffset: number;
    root?: string;
    lastElementCurrent?: HTMLElement | null;
    debug?: boolean;
}

export const InfiniteScroll = (props: Props) => {

    const _lastElement = useRef<any>(null);
    const observer = useRef<any>(null);

    useEffect(() => {
        if (props.isLoading) return;
        if (observer.current) observer.current.disconnect();

        const options: (IntersectionObserverInit | undefined) = props.root !== undefined ? {
            root: document.querySelector(props.root!),
        } : undefined;

        observer.current = new IntersectionObserver((entries) => {
            const [entry] = entries;

            if (entry.isIntersecting && props.offset < props.totalCount && props.nextOffset < props.totalCount) {
                props.setOffset(props.nextOffset);
            }
        }, options);

        if (props.lastElementCurrent !== undefined && props.lastElementCurrent !== null) {
            observer.current.observe(props.lastElementCurrent);
        } else {
            observer.current.observe(_lastElement.current);
        }

    }, [props.isLoading, _lastElement]);

    return <>
        {props.children}
        {props.lastElementCurrent === undefined &&
            <div ref={_lastElement} style={props.debug ? {backgroundColor: 'red', height: 20, width: '100%'} : {}}/>
        }
    </>;
};